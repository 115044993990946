

import { Component, OnInit, ChangeDetectorRef, OnDestroy, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
// import { UserIdleService } from 'angular-user-idle';
import { Injectable, Injector } from '@angular/core';
import { Configuration } from './app.constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of, timer, Subscription } from 'rxjs';
import { SessionTimerComponent } from './core/session-timer/session-timer.component';
import { SessionTimerSitecoreService } from "./core/shared/services/session-timer-sitecore.service";
import { AuthHelper } from "./core/shared/helpers/auth-helper";
import { WaitCursorComponent } from './core/wait-cursor/wait-cursor.component';
import { LoggingService } from './core/shared/services/logging.service';
import { HTTPListener, HTTPStatus } from './core/interceptors/wait-cursor-interceptor';
import { map, switchMap, repeat, takeWhile } from 'rxjs/operators';
import { ErrorService } from './core/shared/services/error.service';
import { INotifyChange, LanguageService } from './core/shared/services/language.service';
import { AppConfig } from '@caloptima/portal-foundation';
import { FooterService } from './member-portal/shared/services/footer.service';
import { MemberProfileService } from './member-portal/shared/services/member-profile.service';
import { ScrollService } from './core/shared/services/scroll.service';

declare var $: any;

@Component({
  selector: 'app-memberPortal',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [SessionTimerComponent]
})
export class AppComponent implements OnInit, OnDestroy {

  getTime$ = of(null)
    .pipe(
      switchMap(e => this.sessionTimer.refreshObservable()) // wait for interval, then emit
      , map(t => this.sessionTimer.getTimeLeftForSessionToExpire()) // get new time
      , repeat()); // start over

  public get expired(): any {
    return !this.authService.hasValidAccessToken();
  }

  public get name() {
    const claims = this.authService.getIdentityClaims();
    if (!claims) {
     return null;
    }

    return claims['given_name'];
  }

  public routeChanged() {
    this.errorService.errorMessage = null;
    this.errorService.error = null;
  }

  title = 'Member Portal Works!';

  private timer;
  showSessionExpiryDialogue: boolean = false;
  timeLeftForSessionToExpire: any;

  public isBusy: boolean = true;
  private subscription: Subscription;

  constructor(private authService: OAuthService, private configuration: Configuration,
   // private userIdle: UserIdleService,
    private idle: Idle,
    private sessionTimer: SessionTimerComponent,
    private sessionTimerSitecoreService: SessionTimerSitecoreService,
    private authHelper: AuthHelper,
    public jwtHelper: JwtHelperService = new JwtHelperService(configuration.AccessTokenName),
    private httpStatus: HTTPStatus,
    private cdRef: ChangeDetectorRef,
    private errorService: ErrorService,
    private languageService: LanguageService,
    private memberProfileService: MemberProfileService,
    public footerService: FooterService,
    private scrollService: ScrollService
  ) {
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => { Promise.resolve(null).then(() => this.isBusy = status); });
  }

  ngOnInit() {

     try {
      this.authService.configure({
        clientId: this.configuration.ClientId,
        redirectUri: this.configuration.RedirectUri,
        scope: this.configuration.Scope,
        issuer: this.configuration.Issuer,
        oidc: true,
        loginUrl: this.configuration.LoginUrl,
        logoutUrl: this.configuration.LogoutUrl
      });

      if (this.authService.hasValidAccessToken() && this.authHelper.tokenNotExpired()) {
          this.sessionTimer = new SessionTimerComponent(this.authService,
            this.configuration, this.sessionTimerSitecoreService, this.jwtHelper, this.languageService);
          if (!this.expired) {
            this.getTime$.pipe(takeWhile(() => this.sessionTimer.checkTimeLeftForTimeout))
              .subscribe(t => {
                this.timeLeftForSessionToExpire = t;
              });
          }
        }

      this.idle.setIdle(900); // how long can they be inactive before considered idle, in seconds
      this.idle.setTimeout(5); // how long can they be idle before considered timed out, in seconds
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

      this.reset();

      // do something when the user becomes idle
      // this.idle.onIdleStart.subscribe(() => {
      // });

      // do something when the user is no longer idle
      this.idle.onIdleEnd.subscribe(() => {
          this.reset();
      });

      // do something when the user has timed out
      this.idle.onTimeout.subscribe(() => {
        this.logOut();
      });

      this.isBusy = false;
    } catch (ex) {
      throw ex;
    }
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public logOut() {
    this.isBusy = true;
    this.authService.logOut();
    sessionStorage.removeItem(this.configuration.SessionStateKey);
    this.memberProfileService.memberProfile = null;
    window.location.href = this.configuration.SessionTimeOutUrl;
    this.isBusy = false;
  }

  getTimeLeftForSessionToExpire(timeLeftForSessionToExpire: string) {
    this.timeLeftForSessionToExpire = timeLeftForSessionToExpire;
  }

  reset() {
    this.idle.watch();
  }
}
