
import {throwError as observableThrowError,  Observable } from 'rxjs';

import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor 
} from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';

import { AuthHelper } from '../shared/helpers/auth-helper';
import { ErrorService } from '../shared/services/error.service';
import {catchError} from 'rxjs/operators'
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private injector: Injector, private errorService: ErrorService ) {
    }
    //authService :OAuthService;
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authService  = this.injector.get(OAuthService);
        const authHelper = this.injector.get(AuthHelper);
        if (authService.hasValidAccessToken() && authHelper.tokenNotExpired()) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authService.getAccessToken()}`
                }
            });
           
        }
        
        return next.handle(request)
            .pipe(
            catchError(error => {
                // do Error mapping here
                return observableThrowError(error);
            }));
    }
}