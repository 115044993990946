import { NgModule } from "@angular/core";
import { RouterModule, provideRoutes, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  {
    path: 'members',
    loadChildren: () => import('./member-portal/member-portal.module').then(m => m.MemberPortalModule)
  },
  {
      path: "surveys",
      loadChildren: () =>
          import('./survey/survey.module').then((m) => m.SurveyModule)
  },
  {
    path: 'core',
    loadChildren: () => import('./core/core.module').then(m => m.CoreModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
