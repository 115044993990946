import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// import { UserIdleModule } from 'angular-user-idle';
import {
  OAuthService,
  UrlHelperService,
  OAuthModule,
} from "angular-oauth2-oidc";

import { SharedModule } from "./shared/shared.module";
import { TokenInterceptor } from "./core/interceptors/token.interceptor";
import { AppComponent } from "./app.component";
import { Configuration } from "./app.constants";
import { CoreModule } from "./core/core.module";
import { MemberPortalModule } from "./member-portal/member-portal.module";
import { MemberProfileService } from "./member-portal/shared/services/member-profile.service";

import { AppConfig } from '@caloptima/portal-foundation';
import { AuthenticationModule, AuthenticationConfig } from '@caloptima/authentication';
import { SessionTimerComponent } from './core/session-timer/session-timer.component';
import { HealthAssessmentModule } from './health-assessment/health-assessment.module';
import {AuthorizationsService} from './member-portal/shared/services/authorizations.service'
import { UserModule } from './user/user.module';
import { LanguageService } from './core/shared/services/language.service';
import { FooterService } from './member-portal/shared/services/footer.service';
import { environment } from '../environments/environment';
import { SurveyModule } from 'survey-angular-ui';

export function initConfig(config: AppConfig) {
    return () => {

      if(environment.production)
        return config.load('api/config');    
      else
        return config.load('assets/config.json');        
    }
  }
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        SharedModule,
        MemberPortalModule,
        CoreModule,
        UserModule,
        AuthenticationModule,
        SurveyModule,
        NgIdleKeepaliveModule.forRoot(),
        OAuthModule.forRoot(),
        JwtModule.forRoot({
            config: {
                // throwNoTokenError: true,
                // skipWhenExpired: true,
                // ...
                tokenGetter
            }
        }),
        // Idle represents idle time, timeout will start once idle time expires, ping will check the idleness
        // UserIdleModule.forRoot({ idle: 900, timeout: 5, ping: 100 })
    ],
    providers: [
        OAuthService,
        AuthorizationsService,
        Configuration,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfig],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        LanguageService,
        FooterService
        
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function tokenGetter(): string {
  return localStorage.getItem("access_token");
}
