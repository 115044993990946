
<div   >
    <app-header *ngIf="!expired"></app-header>

    <wait-cursor [show]="isBusy"></wait-cursor>
    <router-outlet (activate) = "routeChanged()"></router-outlet>
    <session-timer [timeLeftForSessionToExpireInput]="timeLeftForSessionToExpire"></session-timer>

    <app-footer-card></app-footer-card>
    <!-- <app-footer-card *ngIf="footerService && footerService.footerCards && footerService.footerCards.length > 0"></app-footer-card> -->
    <!-- <app-contact-customer-service *ngIf="!expired"></app-codntact-customer-service> -->
    <app-footer></app-footer>
</div>
