/*
** Shared module exposing all shared views, services, and utilities
*/

import { NgModule,Pipe } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


//import { DashboardService } from './services/dashboard.service';
//import { AuthGuardService } from './services/auth-guard.service';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [
       
    //    FilterTextComponent,
    //    InitCapsPipe,
    ],
    providers: [
        //FilterTextService,
        //DashboardService,
        //AuthGuardService,
    ],
    exports: [
        CommonModule,
        FormsModule
        //FilterTextComponent,
        //InitCapsPipe,
        //WaitCursorComponent
        
    ]
})
export class SharedModule { }
